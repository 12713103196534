body {
  background: #ecf0f5 !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Source Sans Pro', 'Helvetica Neue', Helvetica, Arial, sans-serif;
  font-weight: 400;
  overflow-x: hidden; }

/* ==========================================================================
Hide ng-cloak on page load, https://docs.angularjs.org/api/ng/directive/ngCloak
========================================================================== */
[ng\:cloak], [ng-cloak], [data-ng-cloak], [x-ng-cloak], .ng-cloak, .x-ng-cloak {
  display: none !important; }

/* ==========================================================================
Developement Ribbon
========================================================================== */
.ribbon {
  background-color: #a00;
  box-shadow: 0 0 10px #888;
  left: -3.5em;
  moz-box-shadow: 0 0 10px #888;
  moz-transform: rotate(-45deg);
  ms-transform: rotate(-45deg);
  o-transform: rotate(-45deg);
  overflow: hidden;
  position: absolute;
  top: 40px;
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  webkit-box-shadow: 0 0 10px #888;
  webkit-transform: rotate(-45deg);
  white-space: nowrap;
  width: 15em;
  z-index: 9999;
  pointer-events: none; }
  .ribbon a {
    border: 1px solid #faa;
    color: #fff;
    display: block;
    font: bold 81.25% 'Helvetica Neue', Helvetica, Arial, sans-serif;
    margin: 1px 0;
    padding: 10px 50px;
    text-align: center;
    text-decoration: none;
    text-shadow: 0 0 5px #444;
    pointer-events: none; }

/* ==========================================================================
Version number in navbar
========================================================================== */
.navbar-version {
  font-size: 10px;
  color: #ccc; }

/* ==========================================================================
Browser Upgrade Prompt
========================================================================== */
.browserupgrade {
  margin: 0.2em 0;
  background: #ccc;
  color: #000;
  padding: 0.2em 0; }

/* ==========================================================================
Logo styles
========================================================================== */
.navbar-brand {
  padding: 0px; }

.logo-img {
  height: 50px; }

/* ==========================================================================
Main page styles
========================================================================== */
.hero-unit {
  margin: 50px auto 0 auto;
  width: 300px;
  font-size: 18px;
  font-weight: 200;
  line-height: 30px;
  background-color: #eee;
  border-radius: 6px;
  padding: 60px; }
  .hero-unit h1 {
    font-size: 60px;
    line-height: 1;
    letter-spacing: -1px; }

.hipster {
  display: inline-block;
  width: 347px;
  height: 497px;
  background: url("../images/hipster.png") no-repeat center top;
  background-size: contain; }

/* wait autoprefixer update to allow simple generation of high pixel density media query */
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  .hipster {
    background: url("../images/hipster2x.png") no-repeat center top;
    background-size: contain; } }

/* ==========================================================================
Generic styles
========================================================================== */
.error {
  color: white;
  background-color: red; }

.pad {
  padding: 10px; }

.break {
  white-space: normal;
  word-break: break-all; }

.voffset {
  margin-top: 2px; }

.voffset1 {
  margin-top: 5px; }

.voffset2 {
  margin-top: 10px; }

.voffset3 {
  margin-top: 15px; }

.voffset4 {
  margin-top: 30px; }

.voffset5 {
  margin-top: 40px; }

.voffset6 {
  margin-top: 60px; }

.voffset7 {
  margin-top: 80px; }

.voffset8 {
  margin-top: 100px; }

.voffset9 {
  margin-top: 150px; }

.readonly {
  background-color: #eee;
  opacity: 1; }

/* ==========================================================================
make sure browsers use the pointer cursor for anchors, even with no href
========================================================================== */
a:hover {
  cursor: pointer; }

.hand {
  cursor: pointer; }

/* ==========================================================================
Metrics and Health styles
========================================================================== */
#threadDump .popover, #healthCheck .popover {
  top: inherit;
  display: block;
  font-size: 10px;
  max-width: 1024px; }

#healthCheck .popover {
  margin-left: -50px; }

.health-details {
  min-width: 400px; }

/* ==========================================================================
start Password strength bar style
========================================================================== */
ul#strength {
  display: inline;
  list-style: none;
  margin: 0;
  margin-left: 15px;
  padding: 0;
  vertical-align: 2px; }

.point {
  background: #DDD;
  border-radius: 2px;
  display: inline-block;
  height: 5px;
  margin-right: 1px;
  width: 20px; }
  .point:last {
    margin: 0 !important; }

/* ==========================================================================
Custom alerts for notification
========================================================================== */
.alerts .alert {
  text-overflow: ellipsis; }
  .alerts .alert pre {
    background: none;
    border: none;
    font: inherit;
    color: inherit;
    padding: 0;
    margin: 0;
    white-space: normal;
    word-break: break-word; }
  .alerts .alert .popover pre {
    font-size: 10px; }

.alerts .toast {
  position: fixed;
  width: 100%; }
  .alerts .toast.left {
    left: 5px; }
  .alerts .toast.right {
    right: 5px; }
  .alerts .toast.top {
    top: 55px; }
  .alerts .toast.bottom {
    bottom: 55px; }

@media screen and (min-width: 480px) {
  .alerts .toast {
    width: 50%; } }

/* ==========================================================================
entity tables helpers
========================================================================== */
/* Remove Bootstrap padding from the element
   http://stackoverflow.com/questions/19562903/remove-padding-from-columns-in-bootstrap-3 */
.no-padding-left {
  padding-left: 0 !important; }

.no-padding-right {
  padding-right: 0 !important; }

.no-padding-top {
  padding-top: 0 !important; }

.no-padding-bottom {
  padding-bottom: 0 !important; }

.no-padding {
  padding: 0 !important; }

/* bootstrap 3 input-group 100% width
   http://stackoverflow.com/questions/23436430/bootstrap-3-input-group-100-width */
.width-min {
  width: 1% !important; }

/* Makes toolbar not wrap on smaller screens
   http://www.sketchingwithcss.com/samplechapter/cheatsheet.html#right */
.flex-btn-group-container {
  display: -webkit-box;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
          flex-direction: row;
  -webkit-box-pack: end;
          justify-content: flex-end; }

.jh-table > tbody > tr > td {
  /* Align text in td verifically (top aligned by Bootstrap) */
  vertical-align: middle; }

.jh-table > thead > tr > th > .glyphicon-sort, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt {
  /* less visible sorting icons */
  opacity: 0.5; }
  .jh-table > thead > tr > th > .glyphicon-sort:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes:hover, .jh-table > thead > tr > th > .glyphicon-sort-by-attributes-alt:hover {
    /* full visible sorting icons and pointer when mouse is over them */
    opacity: 1;
    cursor: pointer; }

/* ==========================================================================
entity detail page css
========================================================================== */
.dl-horizontal.jh-entity-details > dd {
  margin-bottom: 15px; }

@media screen and (min-width: 768px) {
  .dl-horizontal.jh-entity-details > dt {
    margin-bottom: 15px; }
  .dl-horizontal.jh-entity-details > dd {
    border-bottom: 1px solid #eee;
    padding-left: 180px;
    margin-left: 0; } }

/* ==========================================================================
ui bootstrap tweaks
========================================================================== */
.nav, .pagination, .carousel, .panel-title a {
  cursor: pointer; }

.datetime-picker-dropdown > li.date-picker-menu div > table .btn-default,
.uib-datepicker-popup > li > div.uib-datepicker > table .btn-default {
  border: 0; }

.datetime-picker-dropdown > li.date-picker-menu div > table:focus,
.uib-datepicker-popup > li > div.uib-datepicker > table:focus {
  outline: none; }

/* jhipster-needle-scss-add-main JHipster will add new css style */
/* ==========================================================================

========================================================================== */
.browsehappy {
  margin: 0.2em 0;
  background: #ccc;
  color: black;
  padding: 0.2em 0; }

.event-error {
  background-color: #de252c !important; }

.modal-content {
  border-radius: 4px !important; }

.skin-blue .main-header .navbar {
  background-color: #3c8dbc; }
  .skin-blue .main-header .navbar .nav > li > a {
    color: #fff; }
    .skin-blue .main-header .navbar .nav > li > a:hover {
      background: rgba(0, 0, 0, 0.1);
      color: #f6f6f6; }
    .skin-blue .main-header .navbar .nav > li > a:active {
      background: rgba(0, 0, 0, 0.1);
      color: #f6f6f6; }
    .skin-blue .main-header .navbar .nav > li > a:focus {
      background: rgba(0, 0, 0, 0.1);
      color: #f6f6f6; }
  .skin-blue .main-header .navbar .nav .open > a {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6; }
    .skin-blue .main-header .navbar .nav .open > a:hover {
      background: rgba(0, 0, 0, 0.1);
      color: #f6f6f6; }
    .skin-blue .main-header .navbar .nav .open > a:focus {
      background: rgba(0, 0, 0, 0.1);
      color: #f6f6f6; }
  .skin-blue .main-header .navbar .nav > .active > a {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6; }
  .skin-blue .main-header .navbar .sidebar-toggle {
    color: #fff; }
    .skin-blue .main-header .navbar .sidebar-toggle:hover {
      color: #f6f6f6;
      background: rgba(0, 0, 0, 0.1);
      background-color: #367fa9; }

.skin-blue .main-header .logo {
  background-color: #367fa9;
  color: #fff;
  border-bottom: 0 solid transparent; }
  .skin-blue .main-header .logo:hover {
    background-color: #367fa9; }

.skin-blue .main-header li.user-header {
  background-color: #3c8dbc; }

.skin-blue .content-header {
  background: transparent; }

.skin-blue .wrapper {
  background-color: #222d32; }

.skin-blue .main-sidebar {
  background-color: #222d32;
  position: fixed; }

.skin-blue .left-side {
  background-color: #222d32; }

.skin-blue .sidebar-form {
  border-radius: 3px;
  border: 1px solid #374850;
  margin: 10px; }
  .skin-blue .sidebar-form input[type="text"] {
    box-shadow: none;
    background-color: #374850;
    border: 1px solid transparent;
    height: 35px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #666;
    border-top-left-radius: 2px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 2px; }
    .skin-blue .sidebar-form input[type="text"]:focus {
      background-color: #fff;
      color: #666; }
      .skin-blue .sidebar-form input[type="text"]:focus + .input-group-btn .btn {
        background-color: #fff;
        color: #666;
        border-left-color: #fff; }
  .skin-blue .sidebar-form .btn {
    box-shadow: none;
    background-color: #374850;
    border: 1px solid transparent;
    height: 35px;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
    color: #999;
    border-top-left-radius: 0;
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
    border-bottom-left-radius: 0; }

.skin-blue .user-panel > .info {
  color: #fff; }
  .skin-blue .user-panel > .info > a {
    color: #fff; }

.skin-blue .sidebar-menu > li.header {
  color: #4b646f;
  background: #1a2226; }

.skin-blue .sidebar-menu > li > a {
  border-left: 3px solid transparent; }

.skin-blue .sidebar-menu > li:hover > a {
  color: #fff;
  background: #222d32;
  border-left-color: #3c8dbc; }

.skin-blue .sidebar-menu > li.active > a {
  color: #fff;
  background: #222d32;
  border-left-color: #3c8dbc; }

.skin-blue .sidebar-menu > li > .treeview-menu {
  margin: 0 1px;
  background: #2c3b41; }

.skin-blue .sidebar a {
  color: #b8c7ce; }
  .skin-blue .sidebar a:hover {
    text-decoration: none; }

.skin-blue .treeview-menu > li > a {
  color: #8aa4af; }
  .skin-blue .treeview-menu > li > a:hover {
    color: #fff; }

.skin-blue .treeview-menu > li.active > a {
  color: #fff; }

.skin-blue.layout-top-nav .main-header > .logo {
  background-color: #3c8dbc;
  color: #fff;
  border-bottom: 0 solid transparent; }
  .skin-blue.layout-top-nav .main-header > .logo:hover {
    background-color: #3c8dbc; }

@media (max-width: 767px) {
  .skin-blue .main-header .navbar .dropdown-menu li.divider {
    background-color: rgba(255, 255, 255, 0.1); }
  .skin-blue .main-header .navbar .dropdown-menu li a {
    color: #fff; }
    .skin-blue .main-header .navbar .dropdown-menu li a:hover {
      background: #367fa9; } }

@media screen and (min-width: 768px) {
  .jumbotron {
    border-bottom: 0; }
  timeline-event:nth-child(odd) .timeline-date {
    margin-left: 31px !important;
    background: none !important;
    color: black !important;
    font-size: 13px !important;
    width: 70px !important;
    box-shadow: none !important; }
  timeline-event:nth-child(odd) .timeline-timestamp {
    margin-left: 31px !important;
    background: none !important;
    color: black !important;
    font-size: 13px !important;
    width: 70px !important;
    box-shadow: none !important; }
  timeline-event:nth-child(even) .timeline-date {
    margin-left: -142px !important;
    background: none !important;
    color: black !important;
    font-size: 13px !important;
    width: 70px !important;
    box-shadow: none !important; }
  timeline-event:nth-child(even) .timeline-timestamp {
    margin-left: -142px !important;
    background: none !important;
    color: black !important;
    font-size: 13px !important;
    width: 70px !important;
    box-shadow: none !important; }
  timeline-badge .glyphicon {
    top: 3px; } }

.timeline {
  margin-left: 60px; }

.sidebar-bottom {
  position: fixed;
  bottom: 0;
  cursor: not-allowed; }

.sidebar-top {
  cursor: not-allowed; }

.sidebar-displayed {
  margin-left: 70px !important; }

.fa-times.red-icon {
  color: red;
  margin-left: 5px; }

.sidebar-menu .countdown {
  color: #fff; }

/* ==========================================================================

========================================================================== */
@media (max-width: 767px) {
  .content-wrapper {
    margin-left: 0; }
  .right-side {
    margin-left: 0; }
  .main-footer {
    margin-left: 0; }
  .main-header {
    position: relative; }
    .main-header .navbar-right {
      float: none; }
      .main-header .navbar-right > li {
        color: inherit;
        border: 0; }
    .main-header .logo {
      width: 100%;
      float: none; }
    .main-header .navbar {
      width: 100%;
      float: none;
      margin: 0; }
    .main-header .navbar-custom-menu {
      float: right; }
  .left-side {
    padding-top: 100px;
    -webkit-transform: translate(-230px, 0);
            transform: translate(-230px, 0); }
  .sidebar-open .main-sidebar {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .sidebar-open .left-side {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0); }
  .navbar-nav > .user-menu .user-image {
    float: none;
    margin-right: 0;
    margin-top: -8px;
    line-height: 10px; }
  .navbar-collapse .main-header .navbar-right {
    margin: 7.5px -15px; } }

.main-header {
  position: relative;
  max-height: 100px;
  z-index: 1030; }
  .main-header #navbar-search-input.form-control {
    background: rgba(255, 255, 255, 0.2);
    border-color: transparent; }
    .main-header #navbar-search-input.form-control:focus {
      border-color: rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 0.9); }
    .main-header #navbar-search-input.form-control:active {
      border-color: rgba(0, 0, 0, 0.1);
      background: rgba(255, 255, 255, 0.9); }
    .main-header #navbar-search-input.form-control:-ms-input-placeholder {
      color: #ccc; }
  .main-header #navbar-search-input.form-control::-moz-placeholder {
    color: #ccc;
    opacity: 1; }
  .main-header #navbar-search-input.form-control::-webkit-input-placeholder {
    color: #ccc; }
  .main-header .navbar-custom-menu {
    float: right; }
  .main-header .navbar-right {
    float: right; }
  .main-header .sidebar-toggle {
    float: left;
    background-color: transparent;
    background-image: none;
    padding: 15px;
    font-family: fontAwesome; }
    .main-header .sidebar-toggle:before {
      content: "\f0c9"; }
    .main-header .sidebar-toggle:hover {
      color: #fff; }
    .main-header .sidebar-toggle:focus {
      background: transparent; }
    .main-header .sidebar-toggle:active {
      background: transparent; }
    .main-header .sidebar-toggle .icon-bar {
      display: none; }
  .main-header .logo {
    -webkit-transition: width 0.3s ease-in-out;
    transition: width 0.3s ease-in-out;
    display: block;
    float: left;
    height: 50px;
    font-size: 20px;
    line-height: 50px;
    text-align: center;
    width: 260px;
    font-family: Helvetica Neue, Helvetica, Arial, sans-serif;
    padding: 0 15px;
    font-weight: 300;
    overflow: hidden;
    text-decoration: none; }
    .main-header .logo .logo-lg {
      display: block; }
    .main-header .logo .logo-mini {
      display: none; }
  .main-header .navbar-brand {
    color: #fff; }
  .main-header > .navbar {
    -webkit-transition: margin-left 0.3s ease-in-out;
    transition: margin-left 0.3s ease-in-out;
    margin-bottom: 0;
    margin-left: 230px;
    border: none;
    min-height: 50px;
    border-radius: 0; }
  .main-header .navbar .nav > li.user > a > .fa {
    margin-right: 5px; }
  .main-header .navbar .nav > li.user > a > .glyphicon {
    margin-right: 5px; }
  .main-header .navbar .nav > li.user > a > .ion {
    margin-right: 5px; }
  .main-header .navbar .nav > li > a > .label {
    position: absolute;
    top: 9px;
    right: 7px;
    text-align: center;
    font-size: 9px;
    padding: 2px 3px;
    line-height: .9; }

.sidebar-menu > li {
  position: relative; }
  .sidebar-menu > li > a {
    margin-right: 10px; }

@media (min-width: 768px) {
  .modal-content {
    box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125); }
  .sidebar-collapse .main-sidebar {
    -webkit-transform: translate(-130px, 0);
            transform: translate(-130px, 0); }
  .sidebar-collapse .left-side {
    -webkit-transform: translate(-130px, 0);
            transform: translate(-130px, 0); }
  .sidebar-mini.sidebar-collapse .content-wrapper {
    z-index: 840; }
  .sidebar-mini.sidebar-collapse .right-side {
    z-index: 840; }
  .sidebar-mini.sidebar-collapse .main-footer {
    z-index: 840; }
  .sidebar-mini.sidebar-collapse .main-sidebar {
    -webkit-transform: translate(0, 0);
            transform: translate(0, 0);
    width: 70px !important;
    z-index: 850; }
    .sidebar-mini.sidebar-collapse .main-sidebar .user-panel > .info {
      display: none !important; }
  .sidebar-mini.sidebar-collapse .sidebar-menu > li {
    position: relative; }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > a {
      margin-right: 10px; }
      .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > span {
        border-top-right-radius: 4px;
        display: none !important; }
      .sidebar-mini.sidebar-collapse .sidebar-menu > li > a > .pull-right {
        display: none !important; }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span {
      top: 0;
      margin-left: -3px;
      padding: 12px 5px 12px 20px;
      background-color: inherit; }
      .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > a > span:not(.pull-right) {
        display: block !important;
        position: absolute;
        width: 180px;
        left: 50px; }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:hover > .treeview-menu {
      display: block !important;
      position: absolute;
      width: 180px;
      left: 50px;
      top: 44px;
      margin-left: 0; }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li:not(.treeview) > a > span {
      border-bottom-right-radius: 4px; }
    .sidebar-mini.sidebar-collapse .sidebar-menu > li > .treeview-menu {
      padding-top: 5px;
      padding-bottom: 5px;
      border-bottom-right-radius: 4px;
      display: none !important; }
  .sidebar-mini.sidebar-collapse .sidebar-menu li.header {
    display: none !important; }
  .sidebar-mini.sidebar-collapse .sidebar-form {
    display: none !important; }
  .sidebar-mini.sidebar-collapse .main-header .logo {
    width: 200px;
    position: fixed; }
    .sidebar-mini.sidebar-collapse .main-header .logo > .logo-mini {
      display: none;
      margin-left: -15px;
      margin-right: -15px;
      font-size: 18px; }
    .sidebar-mini.sidebar-collapse .main-header .logo > .logo-lg {
      display: block; }
  .sidebar-mini.sidebar-collapse .main-header .navbar {
    margin-left: 200px; } }

.sidebar-menu {
  white-space: nowrap;
  overflow: hidden;
  list-style: none;
  margin: 0;
  padding: 0; }
  .sidebar-menu > li {
    position: relative;
    margin: 0;
    padding: 0; }
    .sidebar-menu > li.header {
      white-space: nowrap;
      overflow: hidden;
      overflow: hidden;
      text-overflow: clip; }
    .sidebar-menu > li > a {
      padding: 12px 5px 12px 15px;
      display: block; }
    .sidebar-menu > li .label {
      margin-top: 3px;
      margin-right: 5px; }
    .sidebar-menu > li .badge {
      margin-top: 3px;
      margin-right: 5px; }
  .sidebar-menu:hover {
    overflow: visible; }
  .sidebar-menu li > a {
    position: relative; }
    .sidebar-menu li > a > .pull-right {
      position: absolute;
      right: 10px;
      top: 50%;
      margin-top: -7px; }
    .sidebar-menu li > a > .fa-angle-left {
      width: auto;
      height: auto;
      padding: 0;
      margin-right: 10px;
      margin-top: 3px; }
  .sidebar-menu li.header {
    padding: 10px 25px 10px 15px;
    font-size: 12px; }
  .sidebar-menu li.active > a > .fa-angle-left {
    -webkit-transform: rotate(-90deg);
            transform: rotate(-90deg); }
  .sidebar-menu li.active > .treeview-menu {
    display: block; }
  .sidebar-menu .sidebar-bottom {
    position: absolute;
    right: 10px;
    top: 90%;
    margin-top: 50px; }
  .sidebar-menu .treeview-menu {
    display: none;
    list-style: none;
    padding: 0;
    margin: 0;
    padding-left: 5px; }
    .sidebar-menu .treeview-menu .treeview-menu {
      padding-left: 20px; }
    .sidebar-menu .treeview-menu > li {
      margin: 0; }
      .sidebar-menu .treeview-menu > li > a {
        padding: 5px 5px 5px 15px;
        display: block;
        font-size: 14px; }
        .sidebar-menu .treeview-menu > li > a > .fa {
          width: 20px; }
        .sidebar-menu .treeview-menu > li > a > .glyphicon {
          width: 20px; }
        .sidebar-menu .treeview-menu > li > a > .ion {
          width: 20px; }
        .sidebar-menu .treeview-menu > li > a > .fa-angle-left {
          width: auto; }
        .sidebar-menu .treeview-menu > li > a > .fa-angle-down {
          width: auto; }

.main-sidebar {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  width: 70px;
  z-index: 810;
  -webkit-transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }
  .main-sidebar .user-panel {
    white-space: nowrap;
    overflow: hidden; }

.sidebar-form {
  overflow: hidden;
  text-overflow: clip; }
  .sidebar-form input:focus {
    border-color: transparent; }

.modal {
  background: rgba(0, 0, 0, 0.3); }

.modal-content {
  border-radius: 0;
  box-shadow: 0 2px 3px rgba(0, 0, 0, 0.125);
  border: 0; }

.modal-header {
  border-bottom-color: #f4f4f4; }

.modal-footer {
  border-top-color: #f4f4f4; }

.modal-primary .modal-header {
  border-color: #307095; }

.modal-primary .modal-footer {
  border-color: #307095; }

.modal-warning .modal-header {
  border-color: #c87f0a; }

.modal-warning .modal-footer {
  border-color: #c87f0a; }

.modal-info .modal-header {
  border-color: #0097bc; }

.modal-info .modal-footer {
  border-color: #0097bc; }

.modal-success .modal-header {
  border-color: #00733e; }

.modal-success .modal-footer {
  border-color: #00733e; }

.modal-danger .modal-header {
  border-color: #c23321; }

.modal-danger .modal-footer {
  border-color: #c23321; }

.dropdown-menu {
  box-shadow: none;
  border-color: #eee; }
  .dropdown-menu > li > a {
    color: #777; }
    .dropdown-menu > li > a > .glyphicon {
      margin-right: 10px; }
    .dropdown-menu > li > a > .fa {
      margin-right: 10px; }
    .dropdown-menu > li > a > .ion {
      margin-right: 10px; }
    .dropdown-menu > li > a:hover {
      background-color: #e1e3e9;
      color: #333; }
  .dropdown-menu > .divider {
    background-color: #eee; }

@media (max-width: 991px) {
  .navbar-custom-menu .navbar-nav {
    margin: 0;
    float: left; }
    .navbar-custom-menu .navbar-nav > li {
      float: left; }
      .navbar-custom-menu .navbar-nav > li > a {
        padding-top: 15px;
        padding-bottom: 15px;
        line-height: 20px; }
  .navbar-custom-menu > .navbar-nav {
    float: right; }
    .navbar-custom-menu > .navbar-nav > li {
      position: static; }
      .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
        position: absolute;
        right: 5%;
        left: auto;
        border: 1px solid #ddd;
        background: #fff; }
  .main-header .navbar-custom-menu a {
    color: inherit;
    background: transparent; }
  .main-header .navbar-right a {
    color: inherit;
    background: transparent; }
  .navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a {
    background: #fff !important;
    color: #444 !important; }
  .navbar-nav > .messages-menu > .dropdown-menu > li.footer > a {
    background: #fff !important;
    color: #444 !important; }
  .navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a {
    background: #fff !important;
    color: #444 !important; }
  .navbar-nav > .user-menu > .dropdown-menu > .user-body a {
    background: #fff !important;
    color: #444 !important; }
  .navbar-nav > .user-menu > .dropdown-menu > .user-footer .btn-default:hover {
    background-color: #f9f9f9; }
  .content-header > .breadcrumb {
    position: relative;
    margin-top: 5px;
    top: 0;
    right: 0;
    float: none;
    background: #d2d6de;
    padding-left: 10px; }
    .content-header > .breadcrumb li:before {
      color: #97a0b3; }
  .navbar-collapse.pull-left {
    float: none !important; }
    .navbar-collapse.pull-left + .navbar-custom-menu {
      display: block;
      position: absolute;
      top: 0;
      right: 40px; } }

@keyframes flipInX {
  0% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 90deg);
    -webkit-transition-timing-function: ease-in;
            transition-timing-function: ease-in;
    opacity: 0; }
  40% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -20deg);
    -webkit-transition-timing-function: ease-in;
            transition-timing-function: ease-in; }
  60% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
            transform: perspective(400px) rotate3d(1, 0, 0, 10deg);
    opacity: 1; }
  80% {
    -webkit-transform: perspective(400px) rotate3d(1, 0, 0, -5deg);
            transform: perspective(400px) rotate3d(1, 0, 0, -5deg); }
  100% {
    -webkit-transform: perspective(400px);
            transform: perspective(400px); } }

@-webkit-keyframes flipInX {
  0% {
    opacity: 0; }
  40% { }
  60% {
    opacity: 1; }
  80% { }
  100% { } }

.content-header {
  position: relative;
  padding: 15px 15px 0 15px; }
  .content-header > h1 {
    margin: 0;
    font-size: 24px; }
    .content-header > h1 > small {
      font-size: 15px;
      display: inline-block;
      padding-left: 4px;
      font-weight: 300; }
  .content-header > .breadcrumb {
    float: right;
    background: transparent;
    margin-top: 0;
    margin-bottom: 0;
    font-size: 12px;
    padding: 7px 5px;
    position: absolute;
    top: 15px;
    right: 10px;
    border-radius: 2px; }
    .content-header > .breadcrumb > li > a {
      color: #444;
      text-decoration: none;
      display: inline-block; }
      .content-header > .breadcrumb > li > a > .fa {
        margin-right: 5px; }
      .content-header > .breadcrumb > li > a > .glyphicon {
        margin-right: 5px; }
      .content-header > .breadcrumb > li > a > .ion {
        margin-right: 5px; }
    .content-header > .breadcrumb > li + li:before {
      content: ' >\00a0'; }

.navbar-toggle {
  color: #fff;
  border: 0;
  margin: 0;
  padding: 15px; }

.left-side {
  position: absolute;
  top: 0;
  left: 0;
  padding-top: 50px;
  min-height: 100%;
  width: 230px;
  z-index: 810;
  -webkit-transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, width 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out; }

.sidebar {
  padding-bottom: 10px; }

.navbar-nav > .user-menu .user-image {
  float: left;
  width: 25px;
  height: 25px;
  border-radius: 50%;
  margin-right: 10px;
  margin-top: -2px; }

.navbar-nav > .user-menu > .dropdown-menu {
  border-top-right-radius: 0;
  border-top-left-radius: 0;
  padding: 1px 0 0;
  border-top-width: 0;
  width: 280px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px; }
  .navbar-nav > .user-menu > .dropdown-menu > .user-body {
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px;
    padding: 15px;
    border-bottom: 1px solid #f4f4f4;
    border-top: 1px solid #ddd; }
    .navbar-nav > .user-menu > .dropdown-menu > .user-body:before {
      content: " ";
      display: table; }
    .navbar-nav > .user-menu > .dropdown-menu > .user-body:after {
      content: " ";
      display: table;
      clear: both; }
    .navbar-nav > .user-menu > .dropdown-menu > .user-body a {
      color: #444 !important; }
  .navbar-nav > .user-menu > .dropdown-menu > .user-footer {
    background-color: #f9f9f9;
    padding: 10px; }
    .navbar-nav > .user-menu > .dropdown-menu > .user-footer:before {
      content: " ";
      display: table; }
    .navbar-nav > .user-menu > .dropdown-menu > .user-footer:after {
      content: " ";
      display: table;
      clear: both; }
    .navbar-nav > .user-menu > .dropdown-menu > .user-footer .btn-default {
      color: #666666; }
  .navbar-nav > .user-menu > .dropdown-menu > li.user-header {
    height: 175px;
    padding: 10px;
    text-align: center; }
    .navbar-nav > .user-menu > .dropdown-menu > li.user-header > img {
      z-index: 5;
      height: 90px;
      width: 90px;
      border: 3px solid;
      border-color: transparent;
      border-color: rgba(255, 255, 255, 0.2); }
    .navbar-nav > .user-menu > .dropdown-menu > li.user-header > p {
      z-index: 5;
      color: #fff;
      color: rgba(255, 255, 255, 0.8);
      font-size: 17px;
      margin-top: 10px; }
      .navbar-nav > .user-menu > .dropdown-menu > li.user-header > p > small {
        display: block;
        font-size: 12px; }

.navbar-nav > .notifications-menu > .dropdown-menu {
  width: 280px;
  padding: 0;
  margin: 0;
  top: 100%; }
  .navbar-nav > .notifications-menu > .dropdown-menu > li {
    position: relative; }
    .navbar-nav > .notifications-menu > .dropdown-menu > li.header {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      background-color: #fff;
      padding: 7px 10px;
      border-bottom: 1px solid #f4f4f4;
      color: #444;
      font-size: 14px; }
    .navbar-nav > .notifications-menu > .dropdown-menu > li .menu {
      max-height: 200px;
      margin: 0;
      padding: 0;
      list-style: none;
      overflow-x: hidden; }
      .navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a {
        display: block;
        white-space: nowrap;
        border-bottom: 1px solid #f4f4f4;
        color: #444;
        overflow: hidden;
        text-overflow: ellipsis;
        padding: 10px; }
        .navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a:hover {
          background: #f4f4f4;
          text-decoration: none; }
        .navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .glyphicon {
          width: 20px; }
        .navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .fa {
          width: 20px; }
        .navbar-nav > .notifications-menu > .dropdown-menu > li .menu > li > a > .ion {
          width: 20px; }
    .navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      font-size: 12px;
      background-color: #fff;
      padding: 7px 10px;
      border-bottom: 1px solid #eee;
      color: #444 !important;
      text-align: center; }
      .navbar-nav > .notifications-menu > .dropdown-menu > li.footer > a:hover {
        text-decoration: none;
        font-weight: normal; }

.navbar-nav > .messages-menu > .dropdown-menu {
  width: 280px;
  padding: 0;
  margin: 0;
  top: 100%; }
  .navbar-nav > .messages-menu > .dropdown-menu > li {
    position: relative; }
    .navbar-nav > .messages-menu > .dropdown-menu > li.header {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      background-color: #fff;
      padding: 7px 10px;
      border-bottom: 1px solid #f4f4f4;
      color: #444;
      font-size: 14px; }
    .navbar-nav > .messages-menu > .dropdown-menu > li .menu {
      max-height: 200px;
      margin: 0;
      padding: 0;
      list-style: none;
      overflow-x: hidden; }
      .navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a {
        display: block;
        white-space: nowrap;
        border-bottom: 1px solid #f4f4f4;
        margin: 0;
        padding: 10px; }
        .navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:hover {
          background: #f4f4f4;
          text-decoration: none; }
        .navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > h4 {
          padding: 0;
          margin: 0 0 0 45px;
          color: #444;
          font-size: 15px;
          position: relative; }
          .navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > h4 > small {
            color: #999999;
            font-size: 10px;
            position: absolute;
            top: 0;
            right: 0; }
        .navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > p {
          margin: 0 0 0 45px;
          font-size: 12px;
          color: #888888; }
        .navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:before {
          content: " ";
          display: table; }
        .navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a:after {
          content: " ";
          display: table;
          clear: both; }
        .navbar-nav > .messages-menu > .dropdown-menu > li .menu > li > a > div > img {
          margin: auto 10px auto auto;
          width: 40px;
          height: 40px; }
    .navbar-nav > .messages-menu > .dropdown-menu > li.footer > a {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      font-size: 12px;
      background-color: #fff;
      padding: 7px 10px;
      border-bottom: 1px solid #eee;
      color: #444 !important;
      text-align: center; }
      .navbar-nav > .messages-menu > .dropdown-menu > li.footer > a:hover {
        text-decoration: none;
        font-weight: normal; }

.navbar-nav > .tasks-menu > .dropdown-menu {
  width: 280px;
  padding: 0;
  margin: 0;
  top: 100%; }
  .navbar-nav > .tasks-menu > .dropdown-menu > li {
    position: relative; }
    .navbar-nav > .tasks-menu > .dropdown-menu > li.header {
      border-top-left-radius: 4px;
      border-top-right-radius: 4px;
      border-bottom-right-radius: 0;
      border-bottom-left-radius: 0;
      background-color: #fff;
      padding: 7px 10px;
      border-bottom: 1px solid #f4f4f4;
      color: #444;
      font-size: 14px; }
    .navbar-nav > .tasks-menu > .dropdown-menu > li .menu {
      max-height: 200px;
      margin: 0;
      padding: 0;
      list-style: none;
      overflow-x: hidden; }
      .navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a {
        display: block;
        white-space: nowrap;
        border-bottom: 1px solid #f4f4f4;
        padding: 10px; }
        .navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a:hover {
          background: #f4f4f4;
          text-decoration: none; }
        .navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a > h3 {
          font-size: 14px;
          padding: 0;
          margin: 0 0 10px 0;
          color: #666666; }
        .navbar-nav > .tasks-menu > .dropdown-menu > li .menu > li > a > .progress {
          padding: 0;
          margin: 0; }
    .navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a {
      border-top-left-radius: 0;
      border-top-right-radius: 0;
      border-bottom-right-radius: 4px;
      border-bottom-left-radius: 4px;
      font-size: 12px;
      background-color: #fff;
      padding: 7px 10px;
      border-bottom: 1px solid #eee;
      color: #444 !important;
      text-align: center; }
      .navbar-nav > .tasks-menu > .dropdown-menu > li.footer > a:hover {
        text-decoration: none;
        font-weight: normal; }

.open:not(.dropup) > .animated-dropdown-menu {
  -webkit-backface-visibility: visible !important;
          backface-visibility: visible !important;
  -webkit-animation: flipInX 0.7s both;
          animation: flipInX 0.7s both; }

.navbar-custom-menu > .navbar-nav > li {
  position: relative; }
  .navbar-custom-menu > .navbar-nav > li > .dropdown-menu {
    position: absolute;
    right: 0;
    left: auto; }

.layout-top-nav .main-header > .navbar {
  margin-left: 0; }

.content-wrapper, .right-side, .main-footer {
  -webkit-transition: margin 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: margin 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, margin 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
  margin-left: 230px;
  z-index: 820; }

.alert {
  border-radius: 3px; }
  .alert h4 {
    font-weight: 600; }
  .alert .icon {
    margin-right: 10px; }
  .alert .close {
    color: #000;
    opacity: 0.2;
    filter: alpha(opacity=20); }
    .alert .close:hover {
      opacity: 0.5;
      filter: alpha(opacity=50); }
  .alert a {
    color: #fff;
    text-decoration: underline; }

.alert-success {
  border-color: #008d4c;
  color: #fff !important;
  background-color: #00a65a !important; }

.alert-danger {
  border-color: #d73925;
  color: #fff !important; }

.alert-error {
  border-color: #d73925;
  color: #fff !important; }

.alert-warning {
  border-color: #e08e0b;
  color: #fff !important;
  background-color: #f39c12 !important; }

.alert-info {
  border-color: #00acd6;
  color: #fff !important;
  background-color: #00c0ef !important; }

.content-wrapper {
  padding-top: 80px;
  min-height: 100%;
  background-color: #ecf0f5;
  z-index: 800; }

.right-side {
  min-height: 100%;
  background-color: #ecf0f5;
  z-index: 800; }

.main-footer {
  background: #fff;
  padding: 15px;
  color: #444;
  border-top: 1px solid #d2d6de; }

.layout-top-nav .content-wrapper {
  margin-left: 0; }

.layout-top-nav .right-side {
  margin-left: 0; }

.layout-top-nav .main-footer {
  margin-left: 0; }

@media (max-width: 767px) {
  .content-wrapper {
    margin-left: 0; }
  .right-side {
    margin-left: 0; }
  .main-footer {
    margin-left: 0; }
  .sidebar-open .content-wrapper {
    -webkit-transform: translate(230px, 0);
            transform: translate(230px, 0); }
  .sidebar-open .right-side {
    -webkit-transform: translate(230px, 0);
            transform: translate(230px, 0); }
  .sidebar-open .main-footer {
    -webkit-transform: translate(230px, 0);
            transform: translate(230px, 0); }
  .fixed .content-wrapper {
    padding-top: 100px; }
  .fixed .right-side {
    padding-top: 100px; } }

@media (min-width: 768px) {
  .sidebar-collapse .content-wrapper {
    margin-left: 0; }
  .sidebar-collapse .right-side {
    margin-left: 0; }
  .sidebar-collapse .main-footer {
    margin-left: 0; } }

.fixed .main-header {
  position: fixed;
  top: 0;
  right: 0;
  left: 0; }

.fixed .main-sidebar {
  position: fixed; }

.fixed .left-side {
  position: fixed; }

.fixed .content-wrapper {
  padding-top: 50px; }

.fixed .right-side {
  padding-top: 50px; }

.fixed.layout-boxed .wrapper {
  max-width: 100%; }

.bg-red {
  color: #fff !important; }

.bg-yellow {
  color: #fff !important; }

.bg-aqua {
  color: #fff !important; }

.bg-blue {
  color: #fff !important; }

.bg-light-blue {
  color: #fff !important; }

.bg-green {
  color: #fff !important;
  background-color: #00a65a !important; }

.bg-navy {
  color: #fff !important; }

.bg-teal {
  color: #fff !important; }

.bg-olive {
  color: #fff !important; }

.bg-lime {
  color: #fff !important; }

.bg-orange {
  color: #fff !important; }

.bg-fuchsia {
  color: #fff !important; }

.bg-purple {
  color: #fff !important; }

.bg-maroon {
  color: #fff !important; }

.bg-black {
  color: #fff !important; }

.bg-red-active {
  color: #fff !important; }

.bg-yellow-active {
  color: #fff !important; }

.bg-aqua-active {
  color: #fff !important; }

.bg-blue-active {
  color: #fff !important; }

.bg-light-blue-active {
  color: #fff !important; }

.bg-green-active {
  color: #fff !important; }

.bg-navy-active {
  color: #fff !important; }

.bg-teal-active {
  color: #fff !important; }

.bg-olive-active {
  color: #fff !important; }

.bg-lime-active {
  color: #fff !important; }

.bg-orange-active {
  color: #fff !important; }

.bg-fuchsia-active {
  color: #fff !important; }

.bg-purple-active {
  color: #fff !important; }

.bg-maroon-active {
  color: #fff !important; }

.bg-black-active {
  color: #fff !important; }

.callout.callout-danger {
  color: #fff !important; }

.callout.callout-warning {
  color: #fff !important; }

.callout.callout-info {
  color: #fff !important; }

.callout.callout-success {
  color: #fff !important;
  background-color: #00a65a !important; }

.label-danger {
  color: #fff !important; }

.label-info {
  color: #fff !important; }

.label-warning {
  color: #fff !important; }

.label-primary {
  color: #fff !important; }

.label-success {
  color: #fff !important;
  background-color: #00a65a !important; }

.modal-primary .modal-body {
  color: #fff !important; }

.modal-primary .modal-header {
  color: #fff !important; }

.modal-primary .modal-footer {
  color: #fff !important; }

.modal-warning .modal-body {
  color: #fff !important; }

.modal-warning .modal-header {
  color: #fff !important; }

.modal-warning .modal-footer {
  color: #fff !important; }

.modal-info .modal-body {
  color: #fff !important; }

.modal-info .modal-header {
  color: #fff !important; }

.modal-info .modal-footer {
  color: #fff !important; }

.modal-success .modal-body {
  color: #fff !important;
  background-color: #00a65a !important; }

.modal-success .modal-header {
  color: #fff !important; }

.modal-success .modal-footer {
  color: #fff !important; }

.modal-danger .modal-body {
  color: #fff !important; }

.modal-danger .modal-header {
  color: #fff !important; }

.modal-danger .modal-footer {
  color: #fff !important; }

.table-responsive {
  overflow: auto; }
  .table-responsive > .table tr th {
    white-space: normal !important; }
  .table-responsive > .table tr td {
    white-space: normal !important; }

td pre {
  background: none;
  border: none;
  font: inherit;
  color: inherit;
  padding: 0;
  margin: 0;
  white-space: normal !important;
  word-break: break-word; }

body.hold-transition .content-wrapper {
  -webkit-transition: none;
  transition: none; }

body.hold-transition .right-side {
  -webkit-transition: none;
  transition: none; }

body.hold-transition .main-footer {
  -webkit-transition: none;
  transition: none; }

body.hold-transition .main-sidebar {
  -webkit-transition: none;
  transition: none; }

body.hold-transition .left-side {
  -webkit-transition: none;
  transition: none; }

body.hold-transition .main-header .logo {
  -webkit-transition: none;
  transition: none; }

body.hold-transition .main-header > .navbar {
  -webkit-transition: none;
  transition: none; }

.form-control {
  border-radius: 0;
  box-shadow: none;
  border-color: #d2d6de; }
  .form-control:focus {
    border-color: #3c8dbc;
    box-shadow: none; }
  .form-control:-ms-input-placeholder {
    color: #bbb;
    opacity: 1; }
  .form-control:not(select) {
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none; }

.form-control::-moz-placeholder {
  color: #bbb;
  opacity: 1; }

.form-control::-webkit-input-placeholder {
  color: #bbb;
  opacity: 1; }

.form-group.has-success label {
  color: #00a65a; }

.form-group.has-success .form-control {
  border-color: #00a65a;
  box-shadow: none; }

.form-group.has-success .help-block {
  color: #00a65a; }

.form-group.has-warning label {
  color: #f39c12; }

.form-group.has-warning .form-control {
  border-color: #f39c12;
  box-shadow: none; }

.form-group.has-warning .help-block {
  color: #f39c12; }

.form-group.has-error label {
  color: #dd4b39; }

.form-group.has-error .form-control {
  border-color: #dd4b39;
  box-shadow: none; }

.form-group.has-error .help-block {
  color: #dd4b39; }

.input-group .input-group-addon {
  border-radius: 0;
  border-color: #d2d6de;
  background-color: #fff; }

.btn-group-vertical .btn.btn-flat:first-of-type {
  border-radius: 0; }

.btn-group-vertical .btn.btn-flat:last-of-type {
  border-radius: 0; }

.icheck > label {
  padding-left: 0; }

.form-control-feedback.fa {
  line-height: 34px; }

.input-lg + .form-control-feedback.fa {
  line-height: 46px; }

.input-group-lg + .form-control-feedback.fa {
  line-height: 46px; }

.form-group-lg .form-control + .form-control-feedback.fa {
  line-height: 46px; }

.input-sm + .form-control-feedback.fa {
  line-height: 30px; }

.input-group-sm + .form-control-feedback.fa {
  line-height: 30px; }

.form-group-sm .form-control + .form-control-feedback.fa {
  line-height: 30px; }

.btn {
  border-radius: 3px;
  box-shadow: none;
  border: 1px solid transparent; }
  .btn.uppercase {
    text-transform: uppercase; }
  .btn.btn-flat {
    border-radius: 0;
    box-shadow: none;
    border-width: 1px; }
  .btn:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn:focus {
    outline: none; }
  .btn.btn-file {
    position: relative;
    overflow: hidden; }
    .btn.btn-file > input[type='file'] {
      position: absolute;
      top: 0;
      right: 0;
      min-width: 100%;
      min-height: 100%;
      font-size: 100px;
      text-align: right;
      opacity: 0;
      filter: alpha(opacity=0);
      outline: none;
      background: white;
      cursor: inherit;
      display: block; }

.btn-default {
  background-color: #f4f4f4;
  color: #444;
  border-color: #ddd; }
  .btn-default:hover {
    background-color: #e7e7e7; }
  .btn-default:active {
    background-color: #e7e7e7; }
  .btn-default.hover {
    background-color: #e7e7e7; }

.btn-primary {
  background-color: #3c8dbc;
  border-color: #367fa9; }
  .btn-primary:hover {
    background-color: #367fa9; }
  .btn-primary:active {
    background-color: #367fa9; }
  .btn-primary.hover {
    background-color: #367fa9; }

.btn-success {
  background-color: #00a65a;
  border-color: #008d4c; }
  .btn-success:hover {
    background-color: #008d4c; }
  .btn-success:active {
    background-color: #008d4c; }
  .btn-success.hover {
    background-color: #008d4c; }

.btn-info {
  background-color: #00c0ef;
  border-color: #00acd6; }
  .btn-info:hover {
    background-color: #00acd6; }
  .btn-info:active {
    background-color: #00acd6; }
  .btn-info.hover {
    background-color: #00acd6; }

.btn-danger {
  background-color: #dd4b39;
  border-color: #d73925; }
  .btn-danger:hover {
    background-color: #d73925; }
  .btn-danger:active {
    background-color: #d73925; }
  .btn-danger.hover {
    background-color: #d73925; }

.btn-warning {
  background-color: #f39c12;
  border-color: #e08e0b; }
  .btn-warning:hover {
    background-color: #e08e0b; }
  .btn-warning:active {
    background-color: #e08e0b; }
  .btn-warning.hover {
    background-color: #e08e0b; }

.btn-outline {
  border: 1px solid white;
  background: transparent;
  color: white; }
  .btn-outline:hover {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.7); }
  .btn-outline:focus {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.7); }
  .btn-outline:active {
    color: rgba(255, 255, 255, 0.7);
    border-color: rgba(255, 255, 255, 0.7); }

.btn-link {
  box-shadow: none; }

.btn-app {
  border-radius: 3px;
  position: relative;
  padding: 15px 5px;
  margin: 0 0 10px 10px;
  min-width: 80px;
  height: 60px;
  text-align: center;
  color: #666;
  border: 1px solid #ddd;
  background-color: #f4f4f4;
  font-size: 12px; }
  .btn-app > .fa {
    font-size: 20px;
    display: block; }
  .btn-app > .glyphicon {
    font-size: 20px;
    display: block; }
  .btn-app > .ion {
    font-size: 20px;
    display: block; }
  .btn-app > .badge {
    position: absolute;
    top: -3px;
    right: -10px;
    font-size: 10px;
    font-weight: 400; }
  .btn-app:hover {
    background: #f4f4f4;
    color: #444;
    border-color: #aaa; }
  .btn-app:active {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }
  .btn-app:focus {
    box-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125); }

.bg-red {
  background-color: #dd4b39 !important; }

.alert-danger {
  background-color: #dd4b39 !important; }

.alert-error {
  background-color: #dd4b39 !important; }

.label-danger {
  background-color: #dd4b39 !important; }

.btn[class*='bg-']:hover {
  box-shadow: inset 0 0 100px rgba(0, 0, 0, 0.2); }

.callout.callout-danger {
  background-color: #dd4b39 !important; }

.modal-danger .modal-body {
  background-color: #dd4b39 !important; }

.container-relative {
  position: relative; }

.table.no-border {
  border: 0; }
  .table.no-border td {
    border: 0; }
  .table.no-border th {
    border: 0; }

.table.align th {
  text-align: left; }

.table.align td {
  text-align: right; }

.table > thead > tr > th {
  border-top: 1px solid #f4f4f4;
  border-bottom: 2px solid #f4f4f4; }

.table > thead > tr > td {
  border-top: 1px solid #f4f4f4; }

.table > tbody > tr > th {
  border-top: 1px solid #f4f4f4; }

.table > tbody > tr > td {
  border-top: 1px solid #f4f4f4; }

.table > tfoot > tr > th {
  border-top: 1px solid #f4f4f4; }

.table > tfoot > tr > td {
  border-top: 1px solid #f4f4f4; }

.table tr td .progress {
  margin-top: 5px; }

.table-bordered {
  border: 1px solid #f4f4f4; }
  .table-bordered > thead > tr > th {
    border: 1px solid #f4f4f4;
    border-bottom-width: 2px; }
  .table-bordered > thead > tr > td {
    border: 1px solid #f4f4f4;
    border-bottom-width: 2px; }
  .table-bordered > tbody > tr > th {
    border: 1px solid #f4f4f4; }
  .table-bordered > tbody > tr > td {
    border: 1px solid #f4f4f4; }
  .table-bordered > tfoot > tr > th {
    border: 1px solid #f4f4f4; }
  .table-bordered > tfoot > tr > td {
    border: 1px solid #f4f4f4; }

table.text-center {
  text-align: center; }
  table.text-center td {
    text-align: center; }
  table.text-center th {
    text-align: center; }

input[type="checkbox"] {
  display: inline-block;
  *display: inline;
  vertical-align: middle;
  margin: 0 !important;
  padding: 0;
  width: 18px;
  height: 18px;
  border: none;
  cursor: pointer;
  background-position: 0 0; }

.allowedChannels {
  margin: 11px;
  position: relative; }

i.sidebar-icons.glyphicon {
  font-size: 2em; }

@media (max-width: 767px) {
  .navbar-default .navbar-nav .open .dropdown-menu > li > a {
    color: white; } }

.sch-navbar {
  border-width: 0px;
  padding-right: 10px;
  background-color: #3c8dbc; }
  .sch-navbar .nav > li > a {
    color: white; }
    .sch-navbar .nav > li > a:hover {
      background: rgba(0, 0, 0, 0.1);
      color: #f6f6f6; }
    .sch-navbar .nav > li > a:active {
      background: rgba(0, 0, 0, 0.1);
      color: #f6f6f6; }
    .sch-navbar .nav > li > a:focus {
      background: rgba(0, 0, 0, 0.1);
      color: #f6f6f6; }
  .sch-navbar .nav .open > a {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6; }
    .sch-navbar .nav .open > a:hover {
      background: rgba(0, 0, 0, 0.1);
      color: #f6f6f6; }
    .sch-navbar .nav .open > a:focus {
      background: rgba(0, 0, 0, 0.1);
      color: #f6f6f6; }
  .sch-navbar .nav > .active > a {
    background: rgba(0, 0, 0, 0.1);
    color: #f6f6f6; }
    .sch-navbar .nav > .active > a:hover {
      background: rgba(0, 0, 0, 0.1);
      color: #f6f6f6; }
    .sch-navbar .nav > .active > a:focus {
      background: rgba(0, 0, 0, 0.1);
      color: #f6f6f6; }

.canvas-viewer {
  position: fixed;
  height: 100%;
  max-height: 100%;
  width: 100%;
  max-width: 100%;
  padding-top: 10px;
  padding-bottom: 10px;
  padding-right: 30px; }

#startPicker, #endPicker {
  float: right !important;
  padding-left: 15px !important;
  padding-right: 15px !important; }

#chart {
  width: 825px !important; }

.to-left {
  float: left !important; }

.to-right {
  float: right !important; }

#granularityBtn {
  margin-bottom: 40px; }

#chartForm {
  margin-top: 135px; }

#resultsTable {
  overflow: auto !important; }

#results {
  margin-top: 80px;
  margin-bottom: 0px !important; }

.results-head {
  background-color: #D5D8DD;
  text-align: center; }

.align-text {
  text-align: center; }

.table-responsive > .table tr th {
  white-space: nowrap !important; }

.canvas-container {
  position: relative;
  border: 1px solid black;
  margin: 0 auto 1rem; }

.canvas-container > canvas {
  width: 100%;
  height: 100%; }

.canvas-controls {
  position: absolute;
  bottom: 0;
  right: 0;
  font-size: 2.5rem; }

.canvas-controls > div {
  display: inline;
  margin-right: 0.5rem;
  color: black; }

.glyphicon-spin {
  -webkit-animation: spin 1000ms infinite linear;
  animation: spin 1000ms infinite linear; }

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg); } }

@media screen and (min-width: 768px) {
  .g-recaptcha {
    transform: scale(0.45);
    -webkit-transform: scale(0.45);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

@media screen and (min-width: 991px) {
  .g-recaptcha {
    transform: scale(0.7);
    -webkit-transform: scale(0.7);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

@media screen and (min-width: 1200px) {
  .g-recaptcha {
    transform: scale(0.9);
    -webkit-transform: scale(0.9);
    transform-origin: 0 0;
    -webkit-transform-origin: 0 0; } }

.panel-default {
  border-colour: #EAEAEA !important;
  background-color: #F9F9F9 !important;
  padding: 30px 30px 20px 30px; }

a {
  color: #53B7E8; }

a:hover {
  color: #46A7D7 !important;
  text-decoration: none !important; }

/* to replace value in main.css */
a:focus {
  color: #00416E !important;
  text-decoration: none; }

.btn-primary:focus {
  background-color: #01A19B;
  border-color: #01A19B; }

.btn-primary {
  border-radius: 5px; }

.container {
  padding-left: 35px;
  padding-right: 35px;
  margin-top: 30px; }

.dropdown-menu > .active > a, .dropdown-menu > .active > a:focus, .dropdown-menu > .active > a:hover {
  color: #fff !important;
  background-color: #00416E !important; }

.dropdown-menu > li > a {
  padding: 5px 10px; }

.btn-sm {
  color: #333 !important;
  background-color: #f9f9f9 !important;
  border-color: #D1D1D1 !important;
  border-radius: 5px !important; }

.btn-sm:hover, .btn-sm:active, .btn-sm:focus {
  color: #333 !important;
  background-color: #EAEAEA !important;
  border-color: #D1D1D1 !important; }

.btn-sm.active:hover {
  color: #333 !important;
  background-color: #e8e8e8 !important; }

.input-group-btn, .input-group-btn > .btn {
  background-color: #00B1AA;
  border-color: #00B1AA;
  color: #fff;
  border-radius: 0px 5px 5px 0px; }

.btn-default:hover, .btn-default:active, .btn-default.active {
  color: #fff;
  border-color: #01A19B;
  background-color: #01A19B; }

/* to replace value in main.css */
#datepicker-4214-1690-title:hover {
  color: #333 !important;
  background-color: #eaeaea !important;
  border-color: #d1d1d1 !important; }

.btn-default.focus, .btn-default:focus {
  color: #fff;
  background-color: #01A19B;
  border-color: #01A19B; }

.btn-default:active:hover, .btn-default:active:focus, .btn-default:active.focus, .btn-default.active:hover, .btn-default.active:focus, .btn-default.active.focus {
  color: #fff;
  background-color: #01A19B;
  border-color: #01A19B; }

.btn {
  padding: 7px 12px 6px 12px;
  border-radius: 5px; }

/* to replace value in main.css */
.btn-info {
  background-color: #f9f9f9 !important;
  /* main.css - to change color, add important */
  border-color: #d1d1d1 !important;
  /* main.css - to change color */
  color: #333 !important;
  border-radius: 5px !important; }

.btn-info.hover, .btn-info:active, .btn-info:hover, .btn-info.focus, .btn-info:focus {
  color: #333 !important;
  background-color: #eaeaea !important;
  border-color: #d1d1d1 !important; }

.btn-info.active {
  background: #00B1AA !important;
  color: #fff !important; }

.btn-danger {
  background-color: #ffffff00 !important;
  color: #D74F46 !important;
  margin-left: 1px !important; }

/* to replace value in main.css */
.btn-danger.hover, .btn-danger:active, .btn-danger:hover {
  color: #fff !important;
  background-color: #D74F46 !important;
  border-color: #D74F46 !important; }

/* main.css - add in */
.btn-danger.focus, .btn-danger:focus {
  color: #c1463d !important;
  background-color: #ffffff00 !important;
  border-color: #ffffff00 !important; }

.btn-success {
  color: #333333 !important;
  margin-left: 1px !important; }

.btn-success.hover, .btn-success.active, .btn-success:active, .btn-success:hover {
  color: #020202 !important; }

.btn-success.focus, .btn-success:focus {
  border-color: #ffffff !important;
  background-color: #ffffff !important;
  color: #020202 !important; }

.btn-link {
  color: #53B7E8; }

.btn-link:hover {
  color: #46A7D7; }

.btn-link:focus {
  color: #00416E; }

.btn-default.disabled.focus, .btn-default.disabled:focus, .btn-default.disabled:hover, .btn-default[disabled].focus, .btn-default[disabled]:focus, .btn-default[disabled]:hover, fieldset[disabled] .btn-default.focus, fieldset[disabled] .btn-default:focus, fieldset[disabled] .btn-default:hover {
  background-color: #00B1AA;
  border-color: #00B1AA;
  opacity: .70; }

.btn-primary.disabled.focus, .btn-primary.disabled:focus, .btn-primary.disabled:hover, .btn-primary[disabled].focus, .btn-primary[disabled]:focus, .btn-primary[disabled]:hover, fieldset[disabled] .btn-primary.focus, fieldset[disabled] .btn-primary:focus, fieldset[disabled] .btn-primary:hover {
  background-color: #00B1AA;
  border-color: #00B1AA;
  opacity: .70; }

#send {
  background-color: #00B1AA !important;
  border-color: #00B1AA !important;
  color: #fff !important; }

#send:hover, #send:active, #send:focus {
  background-color: #01A19B !important;
  border-color: #01A19B !important;
  color: #fff !important; }

.text-info {
  color: #333333;
  font-weight: bold; }

.pagination-sm > li > a, .pagination-sm > li > span {
  color: #00416E; }

.pagination > .disabled > a, .pagination > .disabled > a:focus, .pagination > .disabled > a:hover, .pagination > .disabled > span, .pagination > .disabled > span:focus, .pagination > .disabled > span:hover {
  border-color: #fff; }

.pagination > li > a, .pagination > li > span {
  border-color: #fff; }

.pagination > .active > a, .pagination > .active > a:focus, .pagination > .active > a:hover, .pagination > .active > span, .pagination > .active > span:focus, .pagination > .active > span:hover {
  background-color: #00416E;
  border-color: #00416E;
  border-radius: 2px;
  color: #fff !important; }

.pagination > li > a:focus, .pagination > li > a:hover, .pagination > li > span:focus, .pagination > li > span:hover {
  color: #00416E !important;
  border-color: #eee;
  border-radius: 2px; }

thead {
  /* border-bottom:  1px solid #D1D1D1;
    border-top: 1px solid #d1d1d1 !important; */
  border: 1px solid #D1D1D1;
  background-color: #F2F2F2;
  height: 20px !important;
  font-size: 12px; }

.table > thead > tr > th {
  border-bottom: 1px solid #D1D1D1;
  padding: 5px; }

/* to replace value in main.css */
th.text-center, td.text-center.h6 {
  text-align: center; }

.table-striped > tbody > tr:nth-of-type(odd) {
  background-color: #FFF; }

.table-striped > tbody > tr:nth-of-type(even) {
  background-color: #F2F2F2; }

.skin-blue .sidebar-menu > li.active > a, .skin-blue .sidebar-menu > li:hover > a {
  border-left: 5px solid #E36F52;
  opacity: 1; }

/* to replace value in main.css */
#search {
  border-radius: 5px;
  background-color: #00B1AA;
  border-color: #00B1AA;
  color: #fff; }
  #search:hover, #search:active, #search:focus {
    background-color: #01A19B;
    border-color: #01A19B; }

.col-md-4 {
  padding: 15px; }

.dropdown-menu {
  padding: 5px; }

li.date-picker-menu {
  padding: 5px !important; }

h3 {
  margin-top: 10px; }

timeline-panel {
  padding: 10px 20px 10px 20px; }

.content-wrapper {
  padding-top: 100px;
  background-color: #fff; }

/* to replace value in main.css */
.label-info {
  background-color: #5bc0de4f !important;
  color: #00416E !important; }

/* to replace value in main.css */
#MONTH, #DAY, #HOUR {
  color: #E36F52 !important;
  font-weight: 600;
  background-color: #ffffff !important;
  border: 1px solid #E36F52 !important;
  border-radius: 2px !important; }

#MONTH.active, #DAY.active, #HOUR.active {
  color: #ffffff !important;
  background-color: #E36F52 !important;
  border-color: #E36F52 !important;
  border-radius: 2px !important; }

#MONTH:active, #DAY:active, #HOUR:active, #MONTH:focus, #DAY:focus, #HOUR:focus {
  color: #ffffff !important;
  background-color: #E36F52 !important;
  border-color: #E36F52 !important;
  border-radius: 2px !important; }

#MONTH:hover, #DAY:hover, #HOUR:hover {
  color: #ffffff !important;
  background-color: #dc6649 !important;
  border-color: #dc6649 !important;
  border-radius: 2px !important; }

.btn-group > .btn:not(:first-child):not(:last-child):not(.dropdown-toggle) {
  border-radius: 0 !important; }

.results-head {
  background-color: #F2F2F2;
  border-color: #D1D1D1; }

.align-text {
  background-color: #fbfbfb; }

.form-control {
  border-color: #333;
  border-radius: 5px; }

.form-control[disabled], .form-control[readonly], fieldset[disabled] .form-control {
  background-color: #EBEBEB;
  color: #33333385;
  border-color: #bfbfbf; }

.ui-select-bootstrap .ui-select-choices-row.active > span {
  background-color: #00416E; }

.ui-select-bootstrap .ui-select-choices-row > span {
  padding: 5px 10px; }

.sc-btn-circle {
  border: 1px solid #00B1AA;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#00B1AA), to(#00B1AA));
  background-image: linear-gradient(180deg, #00B1AA, #00B1AA);
  margin: 0 20px 20px 0; }

.sc-btn-circle:hover, .sc-btn-circle:focus {
  color: #fff !important;
  border: 1px solid #01A19B !important;
  background-image: -webkit-gradient(linear, left top, left bottom, from(#01A19B), to(#01A19B));
  background-image: linear-gradient(180deg, #01A19B, #01A19B); }

.popover-content {
  padding: 10px 10px 0px 10px;
  color: #333; }

.text-center:hover {
  color: #333; }

.alert-success {
  border-color: #00B1AA !important;
  background-color: #E5F7F6 !important;
  color: #333333 !important;
  font-weight: 500; }

.alert-danger, .alert-error {
  border-color: #D74F46 !important;
  background-color: #FBEDEC !important;
  color: #333333 !important; }

.alert-warning {
  border-color: #E7AB00 !important;
  background-color: #FCF6E5 !important;
  color: #333333 !important;
  margin-top: 30px; }

body {
  background: #ffffff !important; }

/* to replace value in main.css */
.sch-navbar {
  background-color: #00416E; }

/* to replace value in main.css */
.dropdown-menu > li > a {
  color: #333; }

/* to replace value in main.css */
.dropdown-menu > li > a:hover {
  color: #333 !important;
  background-color: #EAEAEA; }

/* to replace value in main.css */
.sch-navbar .nav .open > a, .sch-navbar .nav .open > a:focus, .sch-navbar .nav .open > a:hover, .sch-navbar .nav > .active > a, .sch-navbar .nav > .active > a:focus, .sch-navbar .nav > .active > a:hover, .sch-navbar .nav > li > a:active, .sch-navbar .nav > li > a:focus, .sch-navbar .nav > li > a:hover {
  color: #fff !important; }

/* to replace value in main.css */
.form-control:focus {
  border-color: #00B1AA; }

/* to replace value in main.css */
.btn-primary {
  border-color: #00B1AA;
  background-color: #00B1AA; }

/* to replace value in main.css */
.btn-primary.hover, .btn-primary:active, .btn-primary:hover {
  border-color: #01A19B;
  background-color: #01A19B; }

/* to replace value in main.css */
.info {
  color: #333; }
;